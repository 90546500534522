import React, { useEffect } from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo";
import Navbar from "../components/_App/NavbarStyleFour";
import Footer from "../components/_App/Footer";
import ContactInfo from "../components/Contact/ContactInfo";
import GoogleMap from "../components/Contact/GoogleMap";
import ContactForm from "../components/Contact/ContactForm";
import { logPage } from "../analytics/analytics";
import GoogleManager from "../utils/GoogleManager";

const Contact = () => {
    logPage("Contattaci");

    return (
        <Layout>
            <GoogleManager />
            <SEO title="Contattaci | APPIUS - Software House & Web Agency" />

            <Navbar />
            <ContactForm />

            <ContactInfo />

            <GoogleMap />

            <Footer />
        </Layout>
    );
};

export default Contact;
